import { Injectable } from '@angular/core';
import { combineLatest, filter, map, Observable, switchMap } from 'rxjs';

import {
    ContractType,
    DocumentsClient,
    ExtraProductDto,
    ExtraProductsClient,
    FileResponse,
    ProductBookingDto,
    ProductBookingsClient,
    ProductConsumptionDto,
    ProductConsumptionsClient,
    ProductCountingDto,
    ProductCountingsClient,
    ProductDto,
    ProductsClient,
    SaveProductConsumptionDto,
    SaveProductConsumptionsDto,
    SaveProductCountingDto,
    SaveProductCountingsDto,
    SaveUnmanagedProductDto,
    SaveUnmanagedProductsDto,
    SourceType,
} from '@heidelberg/vmi-subscription-api-client';
import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

import { PrintShopsService } from '@vmi/core';
import { mapToRequestMetadataWithRetry, RequestMetadata } from '@vmi/utils';
import { Transaction } from '@vmi/feature-transactions';

import {
    mapProductBookingDtoToTransaction,
    sortTransactionsByDateDescending,
} from '../../../../feature-transactions/src/lib/functions/transaction.functions';

@Injectable({
    providedIn: 'root',
})
export class InventoryService {
    constructor(
        private readonly productBookingApi: ProductBookingsClient,
        private readonly productApi: ProductsClient,
        private readonly extraProductApi: ExtraProductsClient,
        private readonly productCountingApi: ProductCountingsClient,
        private readonly productConsumptionApi: ProductConsumptionsClient,
        private readonly documentsApi: DocumentsClient,
        private readonly sessionService: CCAuthService,
        private readonly printShopsService: PrintShopsService
    ) {}

    public getAllProductCategories(forCustomer: boolean): Observable<string[]> {
        return this.printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                this.productApi.getCategories(forCustomer ? selectedPrintshop?.customerNumber : undefined)
            )
        );
    }

    public getAllProductUnits(): Observable<string[]> {
        return this.productApi.getUnits();
    }

    public getAllIsoCodes(): Observable<string[]> {
        return this.productApi.getIsoCodes();
    }

    public getExtraProducts(
        searchTerms: string,
        category: string,
        page: number,
        pageSize?: number
    ): Observable<ExtraProductDto[]> {
        return this.printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                this.extraProductApi.getExtraProducts(
                    selectedPrintshop?.customerNumber,
                    searchTerms,
                    category,
                    page,
                    pageSize
                )
            )
        );
    }

    public getExtraProductCategories(): Observable<string[]> {
        return this.printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                this.extraProductApi.getExtraProductCategories(selectedPrintshop?.customerNumber)
            )
        );
    }

    public getProducts(
        contractTypes?: ContractType[] | null,
        shelves?: string[] | null,
        categories?: string[] | null
    ): Observable<RequestMetadata<ProductDto[]>> {
        return this.printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                this.productApi
                    .getProducts(
                        selectedPrintshop?.customerNumber,
                        contractTypes,
                        this.sessionService.getCurrentUser()?.locale,
                        null,
                        shelves,
                        categories
                    )
                    .pipe(mapToRequestMetadataWithRetry())
            )
        );
    }

    public getUnmanagedProduct(materialNumber: string): Observable<ProductDto | null> {
        return this.printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                this.productApi
                    .getProducts(
                        selectedPrintshop?.customerNumber,
                        [ContractType.OocHdm, ContractType.OocThirdParty],
                        this.sessionService.getCurrentUser()?.locale,
                        [materialNumber]
                    )
                    .pipe(map((products) => (products.length ? products[0] : null)))
            )
        );
    }

    public getProductIsUnique(materialNumbers: string[] | null, shelves: string[] | null): Observable<boolean> {
        return this.printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                this.productApi.getProductIsUnique(selectedPrintshop?.customerNumber, shelves, materialNumbers)
            )
        );
    }

    public deleteUnmanagedProduct(materialNumber: string): Observable<void> {
        return combineLatest([this.printShopsService.selectedPrintShop$, this.printShopsService.hasWriteRights$]).pipe(
            filter(([selectedPrintshop, hasWriteRights]) => !!selectedPrintshop && hasWriteRights),
            switchMap(([selectedPrintshop]) =>
                this.productApi.deleteUnmanagedProduct(selectedPrintshop?.customerNumber, [materialNumber])
            )
        );
    }

    public createUnmanagedProduct(saveDto: SaveUnmanagedProductDto): Observable<void> {
        return combineLatest([this.printShopsService.selectedPrintShop$, this.printShopsService.hasWriteRights$]).pipe(
            filter(([selectedPrintshop, hasWriteRights]) => !!selectedPrintshop && hasWriteRights),
            switchMap(([selectedPrintshop]) =>
                this.productApi.postUnmanagedProduct(
                    SaveUnmanagedProductsDto.fromJS({
                        customerNumber: selectedPrintshop?.customerNumber,
                        unmanagedProducts: [saveDto],
                    })
                )
            )
        );
    }

    public updateUnamangedProduct(saveDto: SaveUnmanagedProductDto): Observable<void> {
        return combineLatest([this.printShopsService.selectedPrintShop$, this.printShopsService.hasWriteRights$]).pipe(
            filter(([selectedPrintshop, hasWriteRights]) => !!selectedPrintshop && hasWriteRights),
            switchMap(([selectedPrintshop]) =>
                this.productApi.postUnmanagedProduct(
                    SaveUnmanagedProductsDto.fromJS({
                        customerNumber: selectedPrintshop?.customerNumber,
                        updateExistingProducts: true,
                        unmanagedProducts: [saveDto],
                    })
                )
            )
        );
    }

    public downloadCustomerProductsXlsx(contractTypes: ContractType[]): Observable<FileResponse> {
        return this.printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                this.documentsApi.getCustomerProductsXlsx(
                    selectedPrintshop?.customerNumber,
                    contractTypes,
                    this.sessionService.getCurrentUser()?.locale
                )
            )
        );
    }

    public downloadProductBookingsHistoryXlsx(contractTypes: ContractType[]): Observable<FileResponse> {
        return this.printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                this.documentsApi.getAllProductBookingsXlsx(
                    selectedPrintshop?.customerNumber,
                    contractTypes,
                    this.sessionService.getCurrentUser()?.locale
                )
            )
        );
    }

    public downloadProductMonthlyConsumptionsSummaryXlsx(contractTypes: ContractType[]): Observable<FileResponse> {
        return this.printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                this.documentsApi.getMonthlyConsumptionsSummaryXlsx(
                    selectedPrintshop?.customerNumber,
                    contractTypes,
                    this.sessionService.getCurrentUser()?.locale
                )
            )
        );
    }

    // TBD: extract getProductBookings (rename to getProductTransactions), getAllTransactions, getActiveTransactions
    public getProductBookings(
        productId: string,
        contractType: ContractType,
        dateFrom?: Date
    ): Observable<Transaction[]> {
        return this.printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                this.productBookingApi.get(selectedPrintshop?.customerNumber, productId, [contractType], dateFrom).pipe(
                    map((productBookingDtos) =>
                        productBookingDtos.map((productBookingDto) =>
                            mapProductBookingDtoToTransaction(productBookingDto)
                        )
                    ),
                    map((transactions) => sortTransactionsByDateDescending(transactions))
                )
            )
        );
    }

    public getAllTransactions(dateFrom?: Date): Observable<RequestMetadata<Transaction[]>> {
        return this.printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                this.productBookingApi
                    .get(
                        selectedPrintshop?.customerNumber,
                        null,
                        [ContractType.DispoHeidelberg, ContractType.DispoCustomer],
                        dateFrom
                    )
                    .pipe(
                        map((productBookingDtos) =>
                            productBookingDtos.map((productBookingDto) =>
                                mapProductBookingDtoToTransaction(productBookingDto)
                            )
                        ),
                        map((transactions) => sortTransactionsByDateDescending(transactions))
                    )
            ),
            mapToRequestMetadataWithRetry()
        );
    }

    public getConsumptionReport(dateFrom: Date, dateTo: Date): Observable<RequestMetadata<ProductBookingDto[]>> {
        return this.printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                this.productBookingApi.getAggregetedValues(
                    selectedPrintshop?.customerNumber,
                    null,
                    [ContractType.DispoHeidelberg, ContractType.DispoCustomer],
                    dateFrom,
                    null,
                    dateTo
                )
            ),
            mapToRequestMetadataWithRetry()
        );
    }

    public getActiveTransactions(amount = 5): Observable<RequestMetadata<Transaction[]>> {
        return this.printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                this.productBookingApi
                    .getActive(
                        selectedPrintshop?.customerNumber,
                        [ContractType.DispoHeidelberg, ContractType.DispoCustomer],
                        amount
                    )
                    .pipe(
                        map((productBookingDtos) =>
                            productBookingDtos.map((productBookingDto) =>
                                mapProductBookingDtoToTransaction(productBookingDto)
                            )
                        ),
                        map((transactions) => sortTransactionsByDateDescending(transactions))
                    )
            ),
            mapToRequestMetadataWithRetry()
        );
    }

    public getProductCountings(): Observable<ProductCountingDto[]> {
        return this.printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                this.productCountingApi.get(
                    selectedPrintshop?.customerNumber,
                    this.sessionService.getCurrentUser()?.locale
                )
            )
        );
    }

    public deletePostedUnconfirmedConsumptions(sapUniqueIds: string[]): Observable<void> {
        return combineLatest([this.printShopsService.selectedPrintShop$, this.printShopsService.hasWriteRights$]).pipe(
            filter(([selectedPrintshop, hasWriteRights]) => !!selectedPrintshop && hasWriteRights),
            switchMap(([selectedPrintshop]) =>
                this.productBookingApi.retrieveBack(selectedPrintshop?.customerNumber, sapUniqueIds)
            )
        );
    }

    public saveProductCountings(productCountings: SaveProductCountingDto[]): Observable<void> {
        return combineLatest([this.printShopsService.selectedPrintShop$, this.printShopsService.hasWriteRights$]).pipe(
            filter(([selectedPrintshop, hasWriteRights]) => !!selectedPrintshop && hasWriteRights),
            switchMap(([selectedPrintshop]) =>
                this.productCountingApi.post({
                    customerNumber: selectedPrintshop?.customerNumber,
                    productCountings,
                    source: SourceType.Web,
                } as SaveProductCountingsDto)
            )
        );
    }

    public getProductConsumptions(): Observable<ProductConsumptionDto[]> {
        return this.printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                this.productConsumptionApi.get(
                    selectedPrintshop?.customerNumber,
                    this.sessionService.getCurrentUser()?.locale
                )
            )
        );
    }

    public saveProductConsumptions(productConsumptions: SaveProductConsumptionDto[]): Observable<void> {
        return combineLatest([this.printShopsService.selectedPrintShop$, this.printShopsService.hasWriteRights$]).pipe(
            filter(([selectedPrintshop, hasWriteRights]) => !!selectedPrintshop && hasWriteRights),
            switchMap(([selectedPrintshop]) =>
                this.productConsumptionApi.post({
                    customerNumber: selectedPrintshop?.customerNumber,
                    productConsumptions,
                    source: SourceType.Web,
                } as SaveProductConsumptionsDto)
            )
        );
    }
}
